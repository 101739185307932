



































import { Vue, Component, Prop, Inject } from "vue-property-decorator";
import { Observer } from "mobx-vue";
import { TicketViewModel } from "../viewmodels/ticket-viewmodel";
import { walletStore } from "@/stores/wallet-store";
import { FixedNumber } from "@ethersproject/bignumber";

@Observer
@Component({
    components: {
        Ticket: () =>
            import ("./ticket.vue"),
        ConnectButton: () =>
            import ("@/components/ConnectButton.vue"),
    },
})
export default class Stake extends Vue {
    @Inject() vm!: TicketViewModel;
    @Prop({ default: false }) valid!: boolean;
    @Prop({}) sbAmount!: FixedNumber;

    walletStore = walletStore;

    premiumInputValid = true;
    standardInputValid = true;

    stake() {
        this.$emit("stake");
    }

    inputChanged(val) {
        this.$emit("inputChanged", val);
    }
}
